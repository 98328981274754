// @ts-nocheck
import { all, takeLatest } from 'redux-saga/effects';

import { WeChatConfigTypes, WeChatPaymentStatusTypes, WeChatSetPaymentTypes } from '@store/models/wechat/types';
import { RepositoriesTypes } from '@store/models/repositories/types';
import { HomeDataTypes } from '@store/models/home/types';
import {
	FeeTypeTypes, RedFeeTypeTypes, RedFreeConfigTypes, UserDetailDataTypes
} from '@store/models/detail/types';

import { load } from '@store/models/repositories/sagas';
import { loadWeChatConfig, loadWeChatPayment, loadWeChatPaymentStatus } from '@store/models/wechat/sagas';
import { loadHomeData } from '@store/models/home/sagas';
import {
	loadGetFeeType, loadGetRedFeeType, loadGetRedFreeConfig, loadUserDetailData
} from '@store/models/detail/sagas';
import { WeChatUserInfoTypes, CurrentUserInfoTypes } from '@store/models/mine/types';
import { loadWeChatUserInfo, loadCurrentUserInfo } from '@store/models/mine/sagas';

export default function* rootSaga() {
	return yield all([
		// 样例
		takeLatest(RepositoriesTypes.LOAD_REQUEST, load),
		// 获取微信配置
		takeLatest(WeChatConfigTypes.LOAD_WECHATCONFIG_REQUEST, loadWeChatConfig),
		// 首页列表数据
		takeLatest(HomeDataTypes.LOAD_HOMEDATA_REQUEST, loadHomeData),
		// 用户详情数据
		takeLatest(UserDetailDataTypes.LOAD_USER_DETAIL_REQUEST, loadUserDetailData),
		// 微信支付
		takeLatest(WeChatSetPaymentTypes.LOAD_WECHATPAYMENT_REQUEST, loadWeChatPayment),
		// 个人信息支付列表
		takeLatest(FeeTypeTypes.LOAD_GET_FEETYPE_REQUEST, loadGetFeeType),
		// 红娘支付列表
		takeLatest(RedFeeTypeTypes.LOAD_GET_RED_FEETYPE_REQUEST, loadGetRedFeeType),
		// 微信用户信息
		takeLatest(WeChatUserInfoTypes.LOAD_WECHATUSERINFO_REQUEST, loadWeChatUserInfo),
		// 当前用户信息
		takeLatest(CurrentUserInfoTypes.LOAD_CURRENTUSERINFO_REQUEST, loadCurrentUserInfo),
		// 获取微信支付状态
		takeLatest(WeChatPaymentStatusTypes.LOAD_WECHATPAYMENTSTATUS_REQUEST, loadWeChatPaymentStatus),
		// 红花娘详情页
		takeLatest(RedFreeConfigTypes.LOAD_GET_RFEE_REECONFIG_REQUEST, loadGetRedFreeConfig)
	]);
}
