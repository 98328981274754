/**
 * Action types
 */
export enum WeChatUserInfoTypes {
    LOAD_WECHATUSERINFO_REQUEST = '@mine/LOAD_WECHATUSERINFO_REQUEST',
	LOAD_WECHATUSERINFO_SUCCESS = '@mine/LOAD_WECHATUSERINFO_SUCCESS',
	LOAD_WECHATUSERINFO_FAILURE = '@mine/LOAD_WECHATUSERINFO_FAILURE'
}
export enum CurrentUserInfoTypes {
	LOAD_CURRENTUSERINFO_REQUEST = '@mine/LOAD_CURRENTUSERINFO_REQUEST',
	LOAD_CURRENTUSERINFO_SUCCESS = '@mine/LOAD_CURRENTUSERINFO_SUCCESS',
	LOAD_CURRENTUSERINFO_FAILURE = '@mine/LOAD_CURRENTUSERINFO_FAILURE'
}
/**
 * Data types
 */
export interface WeChatUserInfo {
	'city': string;
	'country': string;
	'create_date': number;
	'headimgurl': string;
	'id': string;
	'is_member'?: boolean;
	'nickname': string;
	'openid': string;
	'province': string;
	'sex': number;
	'type': string;
	'surplus_view_count': number;
	'update_date': number;
	'view_count': number;
}
export interface CurrentUserInfo {
	'city': string;
	'country': string;
	'create_date': number;
	'headimgurl': string;
	'id': string;
	'nickname': string;
	'openid': string;
	'province': string;
	'sex': number;
	'type': string;
	'surplus_view_count': number;
	'update_date': number;
	'view_count': number;
  'due_date': string;
}

/**
 * State types
 */

export interface WeChatUserInfoState {
    readonly data: WeChatUserInfo
    readonly loading: boolean
    readonly error: boolean
}

export interface CurrentUserInfoState {
	readonly data: CurrentUserInfo
	readonly loading: boolean
	readonly error: boolean
}
