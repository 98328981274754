import React from 'react';
import './_MemberNew.scss';
import { Box, Button } from '@material-ui/core';
import { RouteComponentProps, withRouter } from 'react-router';
import {
	getCurrentUserInfo, getUserInfoByCode, getMemberSetting, payMember, getPayStatusByTradeNo, getWechatConfig
} from '@service/api';
import querystring from 'querystring';
import { Toast } from '@components/FeedBack/ToastComponent/ToastComponent';
import { formatDate } from '@libs/dateFormatting';

type Props = RouteComponentProps;
interface State{
	memberList: any[];
	currentType: number;
	headimgurl: string;
	nickname: string;
	memberType: string;
	is_member: boolean;
  	dueDate: string;
}
class Member extends React.PureComponent<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = {
			dueDate: '',
			currentType: 2,
			headimgurl: '',
			nickname: '',
			is_member: false,
			memberType: 'member_half',
			memberList: [
				{
					type: 'member_month',
					price: 199,
					base: 199
				},
				{
					type: 'member_season',
					price: 499,
					base: 166
				},
				{
					type: 'member_half',
					price: 599,
					base: 99
				}
			]
		};
	}

	async componentDidMount() {
		document.title = 'VIP特权';
		let { location: { search } } = this.props;
		search = search.split('?')[1];
		const searchObj: any = querystring.parse(search);
		if (searchObj && searchObj.code) {
			await getUserInfoByCode(searchObj.code);
		}
		await this.setWechatConfig();
		const { setting } = await getMemberSetting();
		Object.keys(setting).forEach((key:any) => {
			setting[key].type = key;
		});
		this.setState({
			memberList: Object.values(setting).sort((pre:any, cur:any) => pre.price - cur.price)
		});
		const userInfo : any = await getCurrentUserInfo();
		this.setState({
			headimgurl: userInfo.weixinmp.headimgurl,
			nickname: userInfo.weixinmp.nickname,
			is_member: userInfo.currentMember.is_member,
			dueDate: this.handleDate(userInfo.currentMember.due_date)
		});
	}

	async componentDidUpdate() {
		await this.setWechatConfig();
	}

	handleDate(t: number) {
		if (!t) {
			return '';
		}
		return formatDate(new Date(t - 0), 'YYYY-MM-DD');
	}

	async setWechatConfig() {
		const response = await getWechatConfig();
		if (response.appId) {
			window.wx.config(response);
			window.wx.error((res:any) => {
				console.log(res);
			});
		}
	}

	changeMember(index: number, type: string): void {
		this.setState({
			memberType: type,
			currentType: index
		});
	}

	async payment() {
		let payment:any = null;
		try {
			payment = await payMember(this.state.memberType);
		} catch (error:any) {
			if (error && error.msg) {
				Toast.default({
					title: error.msg
				});
			}
		}
		if (!payment) {
			return;
		}
		let timer:any = null;
		window.wx.ready(() => {
			window.wx.chooseWXPay({
				timestamp: payment.timeStamp,
				nonceStr: payment.nonceStr,
				package: payment.package,
				signType: payment.signType,
				paySign: payment.paySign,
				success: () => {
					Toast.show({
						title: '支付中，请稍等……'
					});
					timer = setInterval(async () => {
						const result = await getPayStatusByTradeNo(payment.out_trade_no);
						if (result.status === '支付成功') {
							clearInterval(timer);
							await getCurrentUserInfo();
							Toast.hide();
							Toast.default({
								title: '支付成功',
								callback: () => {
									window.location.href = '/mini/member';
								}
							});
						}
					}, 1000);
				},
				fail: () => {
					Toast.hide({
						callback: () => {
							Toast.default({
								title: '微信支付失败'
							});
						}
					});
				}
			});
		});
	}

	render() {
		const {
			memberList, currentType, headimgurl, nickname, dueDate, is_member
		} = this.state;
		const tempArr = ['', '季度', '半年'];
		return (
			<div className="member_box">
				<div className="wrap">
					<div className="user-info">
						<div className="userimg">
							<img src={headimgurl || require('@images/user-img.png')} alt="" />
						</div>
						<div>
							<div className="nickname">
								{nickname || 'nickname'}
								<img src={require('@images/vip_icon.png')} alt="" />
							</div>
							{
								is_member ?
								(<div className="date">
									{dueDate}
									<span>到期</span>
								</div>) :
								(<div className="date">
									<span>未开通会员</span>
								</div>)
							}
						</div>
					</div>
				</div>
				<div className="member_detail">
					<div className="title">会员特权</div>
					<div className="member-item">
						<div className="member-item-title">
							<span />
							查看所有嘉宾
						</div>
						<p className="member-item-info">会员可查看全站所有嘉宾，普通用户仅可查看9位。</p>
					</div>
					<div className="member-item">
						<div className="member-item-title">
							<span />
							查看喜欢我的人
						</div>
						<p className="member-item-info">会员可解锁所有查看喜欢我的人。</p>
						<p className="member-item-info" style={{
							"color": '#E35450'
						}}>会员可跳过申请，直接免费查看所有喜欢我的人的微信。</p>
					</div>
					<div className="member-item">
						<div className="member-item-title">
							<span />
							高级筛选功能
						</div>
						<p className="member-item-info">会员可根据收入学历购房等条件筛选嘉宾。</p>
					</div>
				</div>

				<div className="member_detail member_type_wrap">
					<div className="title">服务类型</div>
					<div className="member-type-box">
						{
							memberList.map((member: any, index:number) => (
								<Box
									key={member.price}
									 className={`member-type ${index === currentType ? 'active ' : ''} ${index === 2 ? 'recommend' : ''}`}
									 onClick={() => this.changeMember(index, member.type)}
								>
									<div className="title">{member.month_count}个月</div>
									<div className="price">
										<span className="price-num">
											{member.base}
										</span>
										<span className="price-unit">
											元/月
										</span>
									</div>
									<div className="desc">
										{ index !== 0 ? `${member.price}元` : ''}
									</div>
								</Box>
							))
						}
					</div>
				</div>
				<div className="footer_new">
					<Button className="member_btn" onClick={() => { this.payment(); }}>立即购买</Button>
				</div>
			</div>
		);
	}
}
export default withRouter(Member);
