import React from 'react';
import {
	Grid, ListItem, List, Button
} from '@material-ui/core';
import uuid from '@libs/uuid';
import './_Home.scss';
import InfiniteScroll from 'react-infinite-scroller';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { ApplicationState } from '@store';
import * as WeChatConfigActions from '@store/models/wechat/actions';
import * as HomeDataActions from '@store/models/home/actions';
import { WeChatConfig } from '@store/models/wechat/types';
import { HomeData } from '@store/models/home/types';
import { withRouter, RouteComponentProps } from 'react-router';
import ProvinceLinkage from '@components/ProvinceLinkage/ProvinceLinkage';
import { StorageCache } from '@libs/utils';
import { Carousel } from 'antd-mobile';
import { getCurrentUserInfo, getUserInfoByCode } from '@service/api';
import querystring from "querystring";
const Ficon: string = require('@images/F.png');
const Micon: string = require('@images/M.png');
const Empty: string = require('@images/empty.png');
const banner: string = require('@images/banner.png');
const more: string = require('@images/more.png');

interface State {
	start: number;
	gender: number;
	limit: number;
	hasMore: boolean;
	searchKeywords: any;
	showProvinceLinkage: boolean;
	filter: any;
	bannerList: string[]
}
interface StateProps {
	wechatConfig: WeChatConfig;
	homeData: HomeData;
	error: any;
	loading: any
}
interface DispatchProps {
	loadWeChatRequest(): object;
	loadHomeDataRequest(p: { gender: number; start: number; limit: number; filter: any, tap?: boolean }): object;
}
type Props = StateProps & DispatchProps & RouteComponentProps;
class Home extends React.PureComponent<Props, State> {
	private dataListRef:any = React.createRef<HTMLDivElement>();

	constructor(props: Props) {
		super(props);
		this.state = {
			start: 0,
			gender: 1, // 1女，2男
			limit: 20,
			hasMore: true,
			filter: [],
			showProvinceLinkage: false,
			searchKeywords: {
				country: '',
				province: {
					name: '', // 省的名称
					code: '' // 省的code
				},
				city: {
					name: '', // 市的名称
					code: '' // 省的code
				}
			},
			bannerList: []
		};
		this.changeSearchKeywords = this.changeSearchKeywords.bind(this);
		this.showSearch = this.showSearch.bind(this);
	}

	async componentDidMount() {
		let { location: { search } } = this.props;
		search = search.split("?")[1];
		let searchObj: any = querystring.parse(search);
		if(searchObj && searchObj.code) {
			await getUserInfoByCode(searchObj.code);
		}
		const { start, limit, filter } = this.state;
		const stste:any = this.state;
		const { loadWeChatRequest, loadHomeDataRequest } = this.props;
		let { gender } = stste;
		if (!!StorageCache.getItem('selected_user') || StorageCache.getItem('selected_user') === '0') {
			gender = StorageCache.getItem('selected_user') * 1;
			this.setState({
				gender
			});
		}
		loadWeChatRequest();
		loadHomeDataRequest({
			start, limit, gender, filter
		});
	}

	componentDidUpdate() {
		const { wechatConfig } = this.props;
		if (wechatConfig.appId) {
			window.wx.config(wechatConfig);
			window.wx.error((res:any) => {
				console.log(res);
			});
			this.setShare();
		}
	}

	handleChange(sex:number, newValue: number) {
		if (sex === newValue) {
			return;
		}
		this.setState({
			gender: newValue,
			start: 0
		}, () => {
			const { loadHomeDataRequest } = this.props;
			const {
				start, gender, limit, searchKeywords
			} = this.state;
			const filter = [
				searchKeywords.country,
				searchKeywords.province.name,
				searchKeywords.city.name
			].filter((item: string) => (item != '不限' && item));
			const tap = true;
			this.dataListRef.current.scrollTop = 0;
			loadHomeDataRequest({
				start, limit, gender, filter, tap
			});
		});
		StorageCache.setItem('selected_user', newValue);
	}

	setShare(): void {
		window.wx.ready(() => {
			// 分享到朋友
			const url = window.location.origin + window.location.pathname;
			window.wx.updateAppMessageShareData({
				title: '一起脱单啊',
				desc: '帮助广大程序员解决单身问题',
				link: url,
				imgUrl: 'https://static.hamu.site/blind/logo.png',
				success: (res:any) => {
				},
				cancel: (err:any) => {
				}
			});
			// 分享到朋友圈
			window.wx.updateTimelineShareData({
				title: '一起脱单啊',
				link: url,
				imgUrl: 'https://static.hamu.site/blind/logo.png',
				success: (res:any) => {
				},
				cancel: (err:any) => {
				}
			});
		});
	}

	getListFN() {
		const { loadHomeDataRequest, homeData: { hasNext, page }, loading } = this.props;
 		if (loading || !hasNext) {
			return;
		}
		const {
			start, gender, limit, searchKeywords
		} = this.state;
		this.setState({
			hasMore: hasNext,
			start: page * limit
		});
		const filter = [
			searchKeywords.country,
			searchKeywords.province.name,
			searchKeywords.city.name
		].filter((item: string) => (item != '不限' && item));
		loadHomeDataRequest({
			start, limit, gender, filter
		});
	}

	getSearchListFN(filter: any) {
		const { loadHomeDataRequest } = this.props;

		const start = 0;
		const { gender, limit } = this.state;
		this.setState({
			start
		});

		const tap = true;
		this.dataListRef.current.scrollTop = 0;
		loadHomeDataRequest({
			start, limit, gender, filter, tap
		});
	}

	toDetail(user: any) {
		const { history } = this.props;
		const path = `/detail/${user.id}/${encodeURIComponent(`${user.name}`)}`;
		history.push(path);
	}

	format(homeData: any) : any {
		let { list, is_member } = homeData;
		let moreArr : any[]= [];
		if(!is_member && StorageCache.getItem("navigate") == 'member') {
			StorageCache.removeItem("navigate");
			this.props.history.push("/user/member");
			return {
				listData: [],
				moreArr
			};
		}
		const temp = Object.create(null);
		list.forEach((item:any) => {
			temp[item.id] = item;
		});
		list = Object.values(temp);
		if (list.length > 0) {
			const len = list.length;
			const n = 3; // 假设每行显示4个
			const lineNum = len % 3 === 0 ? len / 3 : Math.floor((len / 3) + 1);
			const res:any = [];
			for (let i = 0; i < lineNum; i++) {
				const temp = list.slice(i * n, i * n + n);
				res.push(JSON.parse(JSON.stringify(temp)));
				if (!res.id) {
					res.id = uuid();
				}
			}
			if(!is_member && res.length >= 4) {
				moreArr = res[3];
				res.length = 3;
			}
			return {
				listData: res,
				moreArr
			};
		}
		return {
			listData: [],
			moreArr
		};
	}

	// 选择城市后执行的方法
	changeSearchKeywords(obj:any, flag?:boolean) {
		this.setState({
			searchKeywords: obj
		});

		let keywords: any = [];
		keywords = [obj.country, obj.province.name, obj.city.name].filter((item :any) => item);
		if (flag) {
			this.showSearch(false);
			this.getSearchListFN(keywords);
		}
	}

	// 显示或隐藏城市选择
	showSearch(flag: boolean) {
		this.setState({
			showProvinceLinkage: flag
		});
	}
	
	async navigateMember() {
		StorageCache.setItem("navigate","member");
		let record = await getCurrentUserInfo();
		let { weixinmp } = record;
		if(weixinmp && weixinmp.is_member) {
			StorageCache.removeItem("navigate");
			window.location.reload(); 
		}
		if(!weixinmp.is_member) {
			StorageCache.removeItem("navigate");
			this.props.history.push("/user/member");
		}
	}
	// 红娘服务页面
	toMatchmakerDetail(): void {
		const { history } = this.props;
		const path = `/mp/coupon`;
		history.push(path);
	}

	render() {
		const { homeData, loading } = this.props;
		const {
			hasMore,
			searchKeywords,
			start,
			gender,
			showProvinceLinkage,
			bannerList
		} = this.state;
		// 格式化数据
		let { listData, moreArr } = this.format(homeData);
		const temp = Object.create(null);
		listData.forEach((item:any) => {
			if (!item.id) {
				item.id = uuid();
			}
			temp[item.id] = item;
		});
		listData = Object.values(temp);
		const arrowList = [1,2,3];
		return (
			<div className="home-wrap">
				<Carousel
					autoplay
					infinite
					className="banner"
				>
					{ bannerList && bannerList.length ? bannerList.map((banner) => (
						<a
							key={banner}
							href="/"
						>
							<img
								src={banner}
								alt=""
								style={{ width: '100%' }}
							/>
						</a>
					)) : (<div className="banner__img" style={{ backgroundImage : `url(${banner})` }} onClick={() => this.toMatchmakerDetail()} />)}
				</Carousel>
				<div className="tabs-box">
					<Button className={gender === 1 ? 'tab active' : 'tab'} onClick={() => this.handleChange(gender, 1)}>
						<div>女嘉宾</div>
						<i />
					</Button>
					<Button className={gender === 0 ? 'tab active' : 'tab'} onClick={() => this.handleChange(gender, 0)}>
						<div>男嘉宾</div>
						<i />
					</Button>
				</div>
				<ProvinceLinkage
					searchKeywords={searchKeywords}
					showProvin={showProvinceLinkage}
					showSearch={this.showSearch}
					changeSearch={this.changeSearchKeywords}
				/>
				<List className="home" ref={this.dataListRef}>
					<InfiniteScroll
						pageStart={start}
						initialLoad={false}
						loadMore={() => this.getListFN()}
						hasMore={hasMore}
						threshold={0}
						loader={loading && (
							<div className="loader" key={homeData.page} />
						)}
						useWindow={false}
						className="infinite-scroll"
					>
						{listData && listData.length > 0 && listData.map((item: any) => (
							<ListItem key={item.id} className="list-item">
								<Grid container spacing={3}>
									{
										item.map((user: any) => (
											<Grid key={user.id} className="user" item xs={4} onClick={() => this.toDetail(user)}>
												<img
													className="header-img"
													src={`${user.avatar}?imageView2/1/w/156/h/156/roundPic/radius/!50p`}
													alt="头像"
												/>
												<div className="name_gender">
													<div className="username ellipsis-line">{user.name}</div>
													<img
														className="gender"
														src={user.gender === '男' ? Micon : Ficon}
														alt=""
													/>
												</div>
												<div className="age_city">
													<div className="age">{`${user.age}岁`}</div>
													<div className="city ellipsis-line">{user.city}</div>
												</div>
												<div className="school ellipsis-line">{user.university}</div>
											</Grid>
										))
									}
								</Grid>
							</ListItem>
						))}
						{
							moreArr && moreArr.length == 3 ? (<div className="footer__card" onClick={()=>this.navigateMember()}>
								<div className="headers">
									{
										moreArr && moreArr.map((user: any, index:number) => {
											return (<div className="img_box" key={user.id}>
														<img src={user.avatar} />
													</div>);
										})
									}
									<img src={more} />
								</div>
								<div className="view">
									<span>点击查看所有嘉宾</span>
									<p className="arrow"/>
									<p className="arrow"/>
									<p className="arrow"/>
								</div>
							</div>) : ""
						}
						{listData && listData.length == 0 ? (
							<div className="empty_box">
								<div>
									<img src={Empty} alt="空白图" />
									<div>暂无相关嘉宾信息</div>
								</div>
							</div>
						) : ''}
					</InfiniteScroll>
				</List>
			</div>
		);
	}
}

const mapStateToProps = (state: ApplicationState) => ({
	wechatConfig: state.wechatConfig.data,
	homeData: state.homeData.data,
	error: state.homeData.error,
	loading: state.homeData.loading
});
const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
	...WeChatConfigActions,
	...HomeDataActions
}, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Home));
