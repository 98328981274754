import React, { useState, useEffect } from 'react';
import './_index.scss';
import {
	Box, Button, Container, Grid
} from '@material-ui/core';
import { WeChatConfig, WeChatPaymentStatus, WeChatSetPayment } from '@store/models/wechat/types';
import {
	FeeTypeList, RedFeeTypeList, RedFreeConfigList, UserDetailData
} from '@store/models/detail/types';
import { WeChatUserInfo } from '@store/models/mine/types';

import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { ApplicationState } from '@store';
import * as WeChatConfigActions from '@store/models/wechat/actions';
import * as UserDetailDataActions from '@store/models/detail/actions';
import * as MineActions from '@store/models/mine/actions';
import { RouteComponentProps, withRouter } from 'react-router';
import uuid from '@libs/uuid';
import { formatDate } from '@libs/dateFormatting';
import {
	getCanalRedOrderByID, trackRedOrder
} from '@service/api';
import { Toast } from '@components/FeedBack/ToastComponent/ToastComponent';

interface mineProps {
	code: string,
	id: string,
}

interface StateProps {
	wechatConfig: WeChatConfig;
	userDetailData: UserDetailData;
	feeTypeList: FeeTypeList;
	redFeeTypeList: RedFeeTypeList;
	wechatSetPayment: WeChatSetPayment;
	wechatUserInfo: WeChatUserInfo;
	weChatPaymentStatus: WeChatPaymentStatus;
	redFreeConfigList: RedFreeConfigList;
}

interface DispatchProps {
	loadWeChatUserInfoRequest(p: string): void;

	loadWeChatRequest(): void;

	loadUserDetailRequest(p: string): void;

	loadWeChatPaymentRequest(p: { 'customer_id': string, 'fee_type': string }): void;

	loadGetFeeTypeRequest(): void;

	loadWeChatPaymentStatusRequest(p: string): void;

	loadGetRedFeeConfigRequest(): void;
}

type Props = StateProps & DispatchProps & RouteComponentProps & mineProps;

function Detail(props: Props) {
	let [userDetailData, updatePageData] = useState({
		isSuccess: false,
		btnText: '开始跟踪',
		headimgurl: '',
		nickname: '',
		wechat: '',
		order_type: '',
		create_date: 0
	});
	const { match } = props;
	const params: any = match.params;
	useEffect(() => {
		const fetchData = async () => {
			try {
				const resp = await getCanalRedOrderByID(params.id);
				updatePageData({...userDetailData, ...resp, isSuccess: true});
			} catch(e) {
				Toast.default({
					title: e?.msg || '未找到该订单'
				});
			}
		};
		fetchData().then();
	}, [params.id]);

	function startFollowUp() {
		if (userDetailData.isSuccess) {
			trackRedOrder(params.id).then(resp => {
				Toast.default({
					title: '跟踪成功'
				});
				updatePageData({...userDetailData,
					isSuccess: false, 
					btnText: resp.track_status || '跟踪中'
				});
			}).catch(err => {
				Toast.default({
					title: err?.msg || '跟踪失败'
				});
			});
		}
	}

	function handleDate(t: number) {
		if (!t) {
			return '';
		}
		return formatDate(new Date(t - 0), 'YYYY-MM-DD hh:mm:ss');
	}

	return (
		<Container className="follow-up" disableGutters>
			<Container className="wrap" disableGutters>
				<Container className="container">
					<Box className="basic-info">
						<Grid container spacing={3}>
								<Grid item xs={6} className="info-item fu-title">
									<span>头像：</span>
								</Grid>
								<Grid item xs={6} className="info-item">
									<img key={uuid()} className="avatar" src={userDetailData.headimgurl + '?imageView2/1/w/710/h/544' } alt="头像" />
								</Grid>
								<Grid item xs={6} className="info-item fu-title">
									昵称：
								</Grid>
								<Grid item xs={6} className="info-item">
									<div className="info-item-value">
										{userDetailData.nickname}
									</div>
								</Grid>
								<Grid item xs={6} className="info-item fu-title">
									微信号：
								</Grid>
								<Grid item xs={6} className="info-item">
									<div className="info-item-value">
										{userDetailData.wechat}
									</div>
								</Grid>
								<Grid item xs={6} className="info-item fu-title">
									订单类型：
								</Grid>
								<Grid item xs={6} className="info-item">
									<div className="info-item-value">
										{userDetailData.order_type}
									</div>
								</Grid>
								<Grid item xs={6} className="info-item fu-title">
									下单时间：
								</Grid>
								<Grid item xs={6} className="info-item">
									<div className="info-item-value">
										{handleDate(userDetailData.create_date - 0)}
									</div>
								</Grid>
							</Grid>
					</Box>
				</Container>
				<Box className="footer">
					<Grid className="jc-center" container spacing={2}>
						<Grid className="footer-right" item xs={6}>
							<Button
								variant="contained"
								color="secondary"
								className="btn"
								disabled={!userDetailData.isSuccess}
								onClick={() => startFollowUp()}
							>
								{userDetailData.btnText}
							</Button>
						</Grid>
					</Grid>
				</Box>
			</Container>
		</Container>
	);
}

const mapStateToProps = (state: ApplicationState) => ({
	wechatConfig: state.wechatConfig.data,
	userDetailData: state.userDetailData.data,
	feeTypeList: state.feeTypeList.data,
	redFeeTypeList: state.redFeeTypeList.data,
	wechatSetPayment: state.wechatSetPayment.data,
	wechatUserInfo: state.currentUserInfo.data,
	redFreeConfigList: state.redFreeConfigList.data
});
const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
	...WeChatConfigActions,
	...UserDetailDataActions,
	...MineActions
}, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Detail));
