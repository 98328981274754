import React, { Component } from 'react';
import { RedFeeTypeList, RedFreeConfigList } from '@store/models/detail/types';
import { Button, Container, Grid } from '@material-ui/core';
import './_coupon.scss';
import { WeChatConfig } from '@store/models/wechat/types';
import { RouteComponentProps, withRouter } from 'react-router';
import { getUserInfoByCode, redPayment, getCustomerInfo, updateCustomerInfo, setRedServiceStatus , getRedServiceStatus, getPayStatusByTradeNo } from '@service/api';
import { Toast } from '@components/FeedBack/ToastComponent/ToastComponent';
import { ApplicationState } from '@store';
import { bindActionCreators, Dispatch } from 'redux';
import * as WeChatConfigActions from '@store/models/wechat/actions';
import * as UserDetailDataActions from '@store/models/detail/actions';
import { connect } from 'react-redux';
import querystring from 'querystring';
import { Skeleton } from '@material-ui/lab';
import { List, InputItem, Modal } from 'antd-mobile';
import _ from 'lodash';

interface State {
	priceType: string;
	customerId: string;
	visible: boolean;
	redVisible: boolean;
	phoneNumber: string;
	wechatNumber: string;
	phoneError: boolean;
	wechatError: boolean;
	red_name: string;
	red_qrcode: string;
}

interface StateProps {
	wechatConfig: WeChatConfig;
	redFeeTypeList: RedFeeTypeList;
	redFreeConfigList: RedFreeConfigList;
}

interface DispatchProps {
	loadWeChatRequest(): void;

	loadGetRedFeeTypeRequest(): void;

	loadGetRedFeeConfigRequest(): void;
}

type Props = StateProps & DispatchProps & RouteComponentProps;

class MatchmakerDetail extends Component<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = {
			priceType: 'coupon_10',
			customerId: '',
			visible: false,
			redVisible: false,
			phoneNumber: '',
			wechatNumber: '',
			phoneError: false,
			wechatError: false,
			red_name: '',
			red_qrcode: ''
		};
	}

	async componentDidMount() {
		window.scrollTo(0, 0);
		const {
			loadWeChatRequest,
			loadGetRedFeeTypeRequest,
			loadGetRedFeeConfigRequest
		} = this.props;
		this.setShare();
		const { match } = this.props;
		const user: any = match.params;
		this.updateDocumentTitle();
		const param: any = querystring.parse(
			window.location.href.split('?')[1]
		);
		if (Object.prototype.hasOwnProperty.call(param, 'code')) {
			await getUserInfoByCode(param.code);
			window.location.replace(window.location.pathname);
		}
		loadWeChatRequest();
		loadGetRedFeeTypeRequest();
		loadGetRedFeeConfigRequest();
		this.setState({
			customerId: user.id
		});
		await this.getRedService();
	}

	componentDidUpdate() {
		const { wechatConfig } = this.props;
		this.updateDocumentTitle();

		if (wechatConfig.appId) {
			window.wx.config(wechatConfig);
			window.wx.error((res: any) => {
				console.log(res);
			});
		}
	}
	async updateCustomer(): Promise<any> {
		const {
			phoneNumber, 
			wechatNumber,
			wechatError,
			phoneError
		} = this.state;
		if(!phoneNumber || phoneError) {
			Toast.default({
				title: '请输入正确手机号'
			});
			return;
		}	
		if(!wechatNumber || wechatError) {
			Toast.default({
				title: '请输入正确微信号'
			});
			return;
		}
		const customer = await getCustomerInfo();
		customer.privacy_information = customer.privacy_information || {};
		customer.privacy_information = Object.assign({}, customer.privacy_information, {
			"微信号": wechatNumber,
			"手机号": phoneNumber
		})
		this.setState({
			visible: false
		})
		await updateCustomerInfo(customer);
		this.setRedPayment()
	}

	async getRedService() {
		const result = await getRedServiceStatus();
		if(result.is_reserved) {
			this.setState({
				redVisible: true,
				red_name: result.red_name,
				red_qrcode: result.qrcode
			})
		}
		return result;
	}
	// 红娘立即购买
	async setRedPayment(): Promise<any> {
		const { is_reserved } = await this.getRedService();
		if(is_reserved) {
			return;
		}
		const {
			priceType, customerId
		} = this.state;
		let timer: any = null;
		const feeType: string = priceType;
		const { privacy_information : { 微信号, 手机号 } }  = await getCustomerInfo();
		if(!微信号 || !手机号) {
			this.modal();
			return;
		}
		return redPayment(customerId, feeType)
			.then((res: any) => {
				if (res.status === '购买成功') {
					Toast.default({
						title: '购买成功'
					});
					return;
				}
				window.wx.ready(() => {
					window.wx.chooseWXPay({
						timestamp: res.timeStamp,
						nonceStr: res.nonceStr,
						package: res.package,
						signType: res.signType,
						paySign: res.paySign,
						success: (result: any) => {
							Toast.show({
								title: '支付中，请稍等……'
							});

							timer = setInterval(async () => {
								const { status } = await getPayStatusByTradeNo(res.out_trade_no).catch((err: any) => {
									if (timer) {
										clearInterval(timer);
									}
									Toast.hide({
										callback: () => {
											Toast.default({
												title: err.msg
											});
										}
									});
								});
								if ('支付成功' == status) {
									if (timer) {
										clearInterval(timer);
									}
									await setRedServiceStatus();
									await this.getRedService();
								}
							}, 1000);
						},
						fail: (err: any) => {
							Toast.hide({
								callback: () => {
									Toast.default({
										title: '微信支付失败'
									});
								}
							});
						}
					});
				});
			})
			.catch((err: any) => {
				Toast.hide({
					callback: () => {
						Toast.default({
							title: err.msg
						});
					}
				});
			});
	}

	setShare(): void {
		const url = window.location.origin + window.location.pathname;
		window.wx.ready(() => {
			window.wx.updateAppMessageShareData({
				title: '高端1v1精准匹配，全新脱单模式闪亮登场',
				desc:
					'优质会员真实靠谱，资深红娘全程服务，精准高效速配脱单，数据分析深度认知，安全放心高度保密！',
				link: url,
				imgUrl: 'https://static.hamu.site/blind/top.png',
				success: (res: any) => {},
				cancel: (err: any) => {}
			});
			// 分享到朋友圈
			window.wx.updateTimelineShareData({
				title: '高端1v1精准匹配，全新脱单模式闪亮登场',
				link: url,
				imgUrl: 'https://static.hamu.site/blind/top.png',
				success: (res: any) => {},
				cancel: (err: any) => {}
			});
		});
	}

	updateDocumentTitle() {
		document.title = '红娘牵线预约服务';
	}

	changePriceType(priceType: string): void {
		this.setState({
			priceType
		});
	}

	goBack(): void {
		window.location.href = '/';
	}

	modal(): void {
		this.setState({
			visible: true
		});
	}

	render() {
		const { redFreeConfigList, redFeeTypeList } = this.props;
		const {
			visible, phoneNumber, wechatNumber, phoneError, wechatError,redVisible,red_name,red_qrcode
		} = this.state;

		const title = (
			<div>
				<div className="title">完善联系方式</div>
				<div className="order">预约红娘</div>
			</div>
		);

		const codeTitle = (
			<div>
				<div className="title">已为你分配专属红娘顾问</div>
				<div className="name">{red_name}红娘</div>
			</div>
		);
		return (
			<Container className="CouponDetail" disableGutters>
				<Container className="matchmaker-content" disableGutters>
					<img
						className="bg"
						src={require('@images/matchmaker/top.png')}
						alt=""
					/>
					<Container className="details">
						<img
							className="girl"
							src={require('@images/matchmaker/girl.png')}
							alt=""
						/>
						<div className="tips">
							程序员一起脱单啊一直以来的愿景就是为每一个程序员找到自己的另一半。
						</div>
						<div className="tips">
							随着程序员一起脱单啊的发展，越来越多优秀的用户订阅我们，信任我们。根据优秀的你们的需求，我们推出了高端定制化服务--1v1红娘服务。
						</div>
						<div className="tips laster">
							程序员一起脱单啊1v1红娘服务将为优质用户精准匹配心仪的另一半，帮助优秀但忙碌的你提升脱单效率，为不愿将就的你找到甜甜的爱情。
							<img
								className="flower"
								src={require('@images/matchmaker/flower.png')}
								alt=""
							/>
						</div>
						<div className="title">{'1V1红娘服务适合人群 >>>'}</div>

						<div className="tips star">
							<img
								src={require('@images/matchmaker/star.png')}
								alt=""
							/>
							择偶目标明确，只想看心仪的对象类型，不想浪费时间看其他类型。
						</div>

						<div className="tips star">
							<img
								src={require('@images/matchmaker/star.png')}
								alt=""
							/>
							工作已经很累了，不想相亲再过度耗费精力和时间，希望能高效脱单。
						</div>

						<div className="tips star">
							<img
								src={require('@images/matchmaker/star.png')}
								alt=""
							/>
							生活圈子比较小，身边没有合适的人，想要眼光更辽阔，不将就，坚信这个世界上一定有适合自己的、对的人。
						</div>

						<div className="tips star">
							<img
								src={require('@images/matchmaker/star.png')}
								alt=""
							/>
							非常在意个人隐私，不想公开发布个人信息，喜欢一对一沟通。
						</div>

						<div className="tips star">
							<img
								src={require('@images/matchmaker/star.png')}
								alt=""
							/>
							性格内向，不知道如何与心仪对象开启聊天话题，甚至在以前的交往中，也曾因约会不顺利而导致恋情无疾而终。
						</div>

						<div className="tips star laster">
							<img
								src={require('@images/matchmaker/star.png')}
								alt=""
							/>
							或者你只是单纯想要在与心仪对象刚开始接触时，有个冷静的旁观者能与你一起分享一下心情，分析一下情况。
							<div className="icon">
								<img
									className="sanjiao sanjiao1"
									src={require('@images/matchmaker/sanjiao1.png')}
									alt=""
								/>
								<img
									className="sanjiao sanjiao2"
									src={require('@images/matchmaker/sanjiao2.png')}
									alt=""
								/>
							</div>
						</div>
						<div className="title">
							{'购买1v1红娘服务你会获得 >>>'}
							<img
								className="hand"
								src={require('@images/matchmaker/hand.png')}
								alt=""
							/>
						</div>
						<div className="tips star">
							<img
								src={require('@images/matchmaker/star.png')}
								alt=""
							/>
							为你推荐一位或 数位精准匹配的对象。
						</div>
						<div className="tips star">
							<img
								src={require('@images/matchmaker/star.png')}
								alt=""
							/>
							帮你破冰，轻松开启你的浪漫故事。
						</div>
						<div className="tips star laster">
							<img
								src={require('@images/matchmaker/star.png')}
								alt=""
							/>
							根据用户具体情况，如果你需要，可以为你进行更多的个性化贴心服务。可以细致到：聊到什么程度适合约见面，去哪里约会比较好，
							要穿什么样的衣服，今天的约会看不明白ta的态度需要帮忙分析并寻求进一步发展……当然，如果你只是想要倾诉追寻爱情的心情，也随时有人倾听。
						</div>
						<div className="title">{'1v1红娘服务流程 >>>'}</div>
						<div className="tips star">
							<div className="index">1</div>
							<div>
								<div className="index-title">
									扫码报名
									<div className="dots">
										<img
											src={require('@images/matchmaker/dots.png')}
											alt=""
										/>
										<img
											src={require('@images/matchmaker/dots.png')}
											alt=""
										/>
									</div>
								</div>
								<div>
									有1v1精准服务需求的嘉宾可以扫码购买服务，支付完成后扫码添加红娘微信。
								</div>
							</div>
						</div>
						<div className="tips star">
							<div className="index">2</div>
							<div>
								<div className="index-title">
									确认需求
									<div className="dots">
										<img
											src={require('@images/matchmaker/dots.png')}
											alt=""
										/>
										<img
											src={require('@images/matchmaker/dots.png')}
											alt=""
										/>
									</div>
								</div>
								<div>
									1v1红娘会和您确认择偶标准并进行学历认证。
								</div>
							</div>
						</div>
						<div className="tips star">
							<div className="index">3</div>
							<div>
								<div className="index-title">
									达成合作
									<div className="dots">
										<img
											src={require('@images/matchmaker/dots.png')}
											alt=""
										/>
										<img
											src={require('@images/matchmaker/dots.png')}
											alt=""
										/>
									</div>
								</div>
								<div>
									双方对整体和细节服务内容达成一致后，将开始合作。
									<img
										className="flower index"
										src={require('@images/matchmaker/flower.png')}
										alt=""
									/>
								</div>
							</div>
						</div>
						<div className="tips star">
							<div className="index">4</div>
							<div>
								<div className="index-title">
									提供服务
									<div className="dots">
										<img
											src={require('@images/matchmaker/dots.png')}
											alt=""
										/>
										<img
											src={require('@images/matchmaker/dots.png')}
											alt=""
										/>
									</div>
								</div>
								<div>
									红娘团队会严格按照服务内容，推荐约定数量的优质嘉宾并提供后续服务。
								</div>
							</div>
						</div>
						<div className="tips star laster">
							<div className="index">5</div>
							<div>
								<div className="index-title">
									持续跟进
									<div className="dots">
										<img
											src={require('@images/matchmaker/dots.png')}
											alt=""
										/>
										<img
											src={require('@images/matchmaker/dots.png')}
											alt=""
										/>
									</div>
								</div>
								<div>
									1v1红娘将全程跟进服务，及时解决问题，保证服务质量。
									<img
										className="box-img"
										src={require('@images/matchmaker/box.png')}
										alt=""
									/>
								</div>
							</div>
						</div>
						<div className="title">{'服务简介 >>>'}</div>
						<div className="tips star">
							<img
								src={require('@images/matchmaker/star.png')}
								alt=""
							/>
							服务周期：签订合同，1-3个月内，高效为您提供精准服务。1v1会员享有平台优先推荐权。
						</div>
						<div className="tips star">
							<img
								src={require('@images/matchmaker/star.png')}
								alt=""
							/>
							<div>
								服务费用：
								<div>
									推荐
									<span className="red-color">三位</span>
									符合您择偶条件的优质对象，并持续跟进服务，
									{/* <span className="red-color">
										{redFeeTypeList.red_one.price}
										元
									</span> */}
									<span className="red-color">
										价格在3099-7999元
									</span>
									。
								</div>
								<div>
									推荐
									<span className="red-color">六位</span>
									符合您择偶条件的优质对象，并持续跟进服务，
									{/* <span className="red-color">
										{redFeeTypeList.red_three.price}
										元
									</span> */}
									<span className="red-color">
										价格在5999-15999元
									</span>
									。
								</div>
							</div>
						</div>
						<div className="tips star">
							<img
								src={require('@images/matchmaker/star.png')}
								alt=""
							/>
							<div>
								服务费用：
								<div>
									{/* 本页面支付的
									<span className="red-color">10元</span>
									可用于抵扣
									<span className="red-color">100元</span>
									1v1精准匹配费用，定金不退。 */}
									具体服务价格，会根据个人情况及择偶要求差异，由红娘团队进行评估定价。
								</div>
							</div>
						</div>
					</Container>
					<Container className="details qrcode">
						<div className="box-lines">
							<div />
							<div />
						</div>
						<div className="qrcode-box">
							{redFreeConfigList.detail_qrcode ? (
								<img
									src={redFreeConfigList.detail_qrcode}
									alt=""
								/>
							) : (
								<Skeleton
									animation="wave"
									variant="rect"
									width={112}
									height={112}
								/>
							)}
							<div>添加红娘微信，预约1v1服务</div>
						</div>
					</Container>
				</Container>
				<div className="footer">
					<Button
						disableRipple
						className="home-btn btns"
						onClick={() => this.goBack()}
					>
						<img
							className="footer-home-img"
							src={require('@images/matchmaker/home.png')}
							alt=""
						/>
					</Button>
					<Button
						variant="contained"
						color="secondary"
						className="matchmaker-btn"
						// onClick={() => this.modal()}
						onClick={() => this.setRedPayment()}
					>
						<div>支付定金 预约红娘</div>
					</Button>
				</div>
				<Modal
					visible={visible}
					transparent
					maskClosable
					onClose={() => {
						this.setState({
							visible: false
						});
					}}
					title={title}
				>
					<div className="contactInformation">
						<List>
							<InputItem
								type="number"
								maxLength={11}
								value={phoneNumber}
								onChange={(value) => {
									const partten = /^[1][3-8]\d{9}$/;
									this.setState({
										phoneError: !partten.test(value)
									});
									this.setState({
										phoneNumber: value
									});
								}}
								onErrorClick={() => {
									if (phoneError) {
										Toast.info({
											title: '请输入正确的手机号'
										});
									}
								}}
								placeholder="请输入电话"
							>
								电话
							</InputItem>
							<InputItem
								type="text"
								value={wechatNumber}
								onChange={(value) => {
									const partten = /^[a-zA-Z0-9_]+$/;
									this.setState({
										wechatError: !partten.test(value)
									});
									this.setState({
										wechatNumber: value
									});
								}}
								onErrorClick={() => {
									if (wechatError) {
										Toast.info({
											title: '请输入正确的微信'
										});
									  }
								}}
								placeholder="请输入微信"
							>
								微信
							</InputItem>
							<div className="yy" onClick={() => this.updateCustomer()}>
								立即预约
							</div>
						</List>
					</div>
				</Modal>
				<Modal
					visible={redVisible}
					transparent
					maskClosable
					onClose={() => {
						this.setState({
							redVisible: false
						});
					}}
					title={codeTitle}
				>
					<div className="qrcode">
						{red_name ? (
							<img src={red_qrcode} alt="" />
						) : (
							<Skeleton
								animation="wave"
								variant="rect"
								width={112}
								height={112}
							/>
						)}
						<div className="text">扫码添加红娘顾问微信</div>
						<div className="smallText">开启匹配服务</div>
					</div>
				</Modal>
			</Container>
		);
	}
}

const mapStateToProps = (state: ApplicationState) => ({
	wechatConfig: state.wechatConfig.data,
	redFeeTypeList: state.redFeeTypeList.data,
	redFreeConfigList: state.redFreeConfigList.data
});
const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(
	{
		...WeChatConfigActions,
		...UserDetailDataActions
	},
	dispatch
);

export default withRouter(
	connect(mapStateToProps, mapDispatchToProps)(MatchmakerDetail)
);
