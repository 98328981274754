// @ts-nocheck
import { call, put } from "redux-saga/effects";
import { getCurrentUserInfo, getUserInfoByCode } from "@service/api";
import {
	loadCurrentUserInfoFailure,
	loadCurrentUserInfoSuccess,
	loadWeChatUserInfoFailure,
	loadWeChatUserInfoSuccess,
} from "./actions";

export function* loadWeChatUserInfo(action: any) {
	try {
		const response = yield call(getUserInfoByCode, action.payload);
		yield put(loadWeChatUserInfoSuccess(response));
	} catch (e) {
		yield put(loadWeChatUserInfoFailure());
	}
}

export function* loadCurrentUserInfo() {
	try {
		const response: any = yield call(getCurrentUserInfo);
		yield put(loadCurrentUserInfoSuccess(response.weixinmp));
	} catch (e) {
		yield put(loadCurrentUserInfoFailure());
	}
}
