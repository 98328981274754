import React, { Component } from 'react';
import {
	Button, Container
} from '@material-ui/core';
import { RouteComponentProps, withRouter } from 'react-router';
import './_Questionnaire.scss';
import writeInformation from '@images/write-information-mini.png';
import writeFinished from '@images/write-finished.png';
import { paticipant, getMiniWechatConfig } from '@service/api';
interface State {
	title: string;
	code: string;
}


type Props = RouteComponentProps;

class Questionnaire extends Component<Props, State> {
	async componentDidMount() {
		await this.setWechatConfig();
		const { match  } = this.props;
		const info: any = match.params;
		let title  = info.type == "start" ? "一起脱单啊" : "填写完成"; 
		this.setDocumenTitle(title);
		if (info.code) {
			this.setState({
				code:info.code
			})
		}
		this.setState({
			title
		})
	}

	async setWechatConfig() {
		const response = await getMiniWechatConfig();
		if (response.appId) {
			window.wx.config(response);
			window.wx.error((res:any) => {
				console.log(res);
			});
		}
	}
	componentDidUpdate() {
		if(this.state.title) {
			this.setDocumenTitle(this.state.title);
		}
	}

	setDocumenTitle(title:string) {
		document.title = title;
	}
	render() {
		const { match } = this.props;
		const info: any = match.params;
		let content:any = "";
		
		switch(info.type) {
			case "start": 
				content = ( <Container className="mini__question_box">
					<div className="question_box_title">解除曝光限制加速脱单</div>
					<div className="question_box_content">
						程序员一起脱单啊是严肃的相亲交友平台，对于平台所有用户的信息都会严格审核，因为资料信息较多，请先确认你有<span>10分钟</span>左右的时间来完成问卷。
					</div>
					<img src={writeInformation} alt="" />
					<Button className="question_box_button" onClick={()=> {
						const {code} = this.state;
						if(code) {
							paticipant(code).then((res:any)=> {
								if(res && res.weixinmp) {
									window.location.href = `/mini/data`;
								}
							})
						}
					}}>开始填写</Button>
					<p className="give__up" onClick={() => {
						window.wx.miniProgram.navigateBack()
					}}>放弃填写</p>
				</Container> )
				break;
			case "end": 
			content = ( <Container className="mini__question_box">
				<div className="question_box_finished_title">已提交审核</div>
				<div className="question_box_finished_content">恭喜你已经填写完所有资料了，一定是特别的力量支撑着你走到了这里。
接下来，我们的审核人员会进一步审核你的资料。审核完毕后，你就可以被推荐给其他用户啦～</div>
				<img src={writeFinished} alt="" className="question_box_finished_img"/>
				<Button className="question_box_button" onClick={() => 
					window.wx.miniProgram.switchTab({
						url: '/vendor/pages/mine/mine'
					})
				}>已知晓，等待审核人员联系</Button>
			</Container> )
			break;
		}
		return content;
	}
}

export default withRouter(Questionnaire);
