// @ts-nocheck
import { call, put } from 'redux-saga/effects';
import { getPayStatusByTradeNo, getWechatConfig, setPayment } from '@service/api';
import {
	loadWeChatSuccess,
	loadWeChatFailure,
	loadWeChatPaymentSuccess,
	loadWeChatPaymentFailure,
	loadWeChatPaymentStatusSuccess, loadWeChatPaymentStatusFailure
} from './actions';

/**
 * 微信配置
 */
export function* loadWeChatConfig() {
	try {
		const response = yield call(getWechatConfig);
		yield put(loadWeChatSuccess(response));
	} catch (e) {
		yield put(loadWeChatFailure());
	}
}
/**
 * 微信支付
 */
export function* loadWeChatPayment(action: any) {
	try {
		const response = yield call(setPayment, action.payload);
		yield put(loadWeChatPaymentSuccess(response));
	} catch (e) {
		yield put(loadWeChatPaymentFailure());
	}
}

/**
 * 微信状态
 */
export function* loadWeChatPaymentStatus(action: any) {
	try {
		const response = yield call(getPayStatusByTradeNo, action.payload);
		yield put(loadWeChatPaymentStatusSuccess(response));
	} catch (e) {
		yield put(loadWeChatPaymentStatusFailure());
	}
}
