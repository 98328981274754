import axios from 'axios';

axios.defaults.withCredentials = true;

const route = {
	rootURL: '/gateway/api/jsonrpc.jsp'
};

const directJsonrpc = function (method: any, parameters: any, callback: any) {
	if (method && typeof method === 'string' && parameters) {
		if (typeof parameters === 'function') {
			callback = parameters;
			parameters = [];
		} else if (!Array.isArray(parameters)) {
			parameters = [parameters];
		}

		const query = {
			jsonrpc: '2.0',
			id: Date.now(),
			method,
			params: parameters
		};

		axios.post(route.rootURL, JSON.stringify(query)).then((response) => {
			if (callback && typeof callback === 'function') {
				if (response.data.error) {
					callback(response.data.error, undefined);
				} else {
					callback(undefined, response.data.result);
				}
			}
		}).catch((ex) => {
			if (callback && typeof callback === 'function') {
				const error = {
					code: 490,
					msg: ex.toString()
				};
				callback(error);
			}
		});
	} else {
		throw new Error('parameter format error');
	}
};

const saveCredential = function (id: any) {
	if (id) {
		window.localStorage.setItem('ham.rpc.cookie', id);
	} else if (!(id && window.localStorage.getItem('ham.rpc.cookie') === id)) {
		window.localStorage.setItem('ham.rpc.cookie', '');
	}
};

let status = 0;
const queue: any = [];

const doLogin = function() {
	const appID = 'wxb96c4b986765f537';
	const url = window.location.origin + window.location.pathname;
	const redirectUri = process.env.NODE_ENV === 'development' ? 'https://mp.yiqituodan.com/mine' : encodeURIComponent(url);
	window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appID}&redirect_uri=${redirectUri}&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect`;
}


const doParticipant = function (callback:any) {
	status = 1;
	let token = window.localStorage.getItem("ham.rpc.cookie");
    directJsonrpc("blind@h5.paticipant", [token], function (error:any, result:any) {
		status = 0

		if(result && result.id) {
			saveCredential(result.id);
		}

		if(error) {
			doLogin();
			return;
		}

		if (result && result.id && result.weixinmp && result.weixinmp.openid) {
            queue.forEach(function (context:any) {
                directJsonrpc(context.path, context.parameters, context.callback)
            })
            callback(null, result)
        } else {
			doLogin();
			return;
        }  
    })
};

const buildProxy = function (name: any, context: any) {
	return new Proxy(function () {
		const args = Array.prototype.slice.call(arguments, 0);
		return new Promise((resolve, reject) => {
			jsonrpc(`${context.join('@')}.${name}`, args, (error: any, result: any) => {
				if (error) {
					reject(error);
				} else {
					resolve(result);
				}
			});
		});
	}, {
		get(target, method) {
			// @ts-ignore
			if (!target[method]) {
				// @ts-ignore
				target[method] = buildProxy(method, context.concat([name]));
			}
			// @ts-ignore
			return target[method];
		}
	});
};

const jsonrpc = function (path: any, parameters: any, callback: any) {
	if (status === 0) {
		directJsonrpc(path, parameters, (error: any, result: any) => {
			if (error) {
				if (error.code === 592) {
					doParticipant(function (error:any) {
                        if (error) {
                            callback(error)
                        } else {
                            directJsonrpc(path, parameters, callback)
                        }
                    });
				} else {
					callback && callback(error);
				}
			} else {
				callback && callback(null, result);
			}
		});
	} else {
		queue.push({
			path,
			parameters,
			callback
		});
	}
};

// @ts-ignore
jsonrpc.directJsonrpc = directJsonrpc;
// @ts-ignore
jsonrpc.saveCredential = saveCredential;
// @ts-ignore
jsonrpc.doParticipant = doParticipant;

interface clientInter {
  [key:string]:any
}

const jsonrpcClient:clientInter = new Proxy(function () {
	// @ts-ignore
	return jsonrpc.apply(jsonrpc, arguments);
}, {
	get(target, domain:string) {
		switch (domain) {
			case 'direct':
				return directJsonrpc;
			case 'saveCredential':
				return saveCredential;
			case 'doParticipant':
				return doParticipant;
			default:
				if (['name', 'length', 'bind'].indexOf(domain) < 0) {
					// @ts-ignore
					if (!target[domain]) {
						// @ts-ignore
						target[domain] = new Proxy({}, {
							get(subTarget, method:string) {
								if (['bind', 'length', 'name'].indexOf(method) < 0) {
									// @ts-ignore
									if (!subTarget[method]) {
										// @ts-ignore
										subTarget[method] = buildProxy(method, [domain]);
									}
									// @ts-ignore
									return subTarget[method];
								}
								// @ts-ignore
								return subTarget[method];
							}
						});
					}
					// @ts-ignore
					return target[domain];
				}
				// @ts-ignore
				return target[domain];
		}
	}
});
window.jsonrpc = jsonrpcClient;

export default jsonrpcClient;
