import { Reducer } from 'redux';
import {
	CurrentUserInfoState,
	CurrentUserInfoTypes, WeChatUserInfoState, WeChatUserInfoTypes
} from './types';

const INITIAL_WECHATUSERINFO_STATE: WeChatUserInfoState = {
	data: {
		is_member: false,
		city: '',
		country: '',
		create_date: 0,
		headimgurl: '',
		id: '',
		nickname: '',
		openid: '',
		province: '',
		sex: 0,
		type: 'count',
		surplus_view_count: 0,
		update_date: 0,
		view_count: 0
	},
	error: false,
	loading: false
};

const reducer: Reducer<WeChatUserInfoState> = (state = INITIAL_WECHATUSERINFO_STATE, aciton) => {
	switch (aciton.type) {
		case WeChatUserInfoTypes.LOAD_WECHATUSERINFO_REQUEST:
			return {
				...state, loading: true
			};
		case WeChatUserInfoTypes.LOAD_WECHATUSERINFO_SUCCESS:
			return {
				...state, loading: false, error: false, data: aciton.payload.data
			};
		case WeChatUserInfoTypes.LOAD_WECHATUSERINFO_FAILURE:
			return {
				...state, loading: false, error: true
			};
		default:
			return state;
	}
};

const INITIAL_CURRENTUSERINFO_STATE: CurrentUserInfoState = {
	data: {
		city: '',
		country: '',
		create_date: 0,
		headimgurl: '',
		id: '',
		nickname: '',
		openid: '',
		province: '',
		sex: 0,
		type: 'count',
		surplus_view_count: 0,
		update_date: 0,
		view_count: 0,
		due_date: ''
	},
	error: false,
	loading: false
};

const currentUserReducer: Reducer<CurrentUserInfoState> = (state = INITIAL_CURRENTUSERINFO_STATE, aciton) => {
	switch (aciton.type) {
		case CurrentUserInfoTypes.LOAD_CURRENTUSERINFO_REQUEST:
			return {
				...state, loading: true
			};
		case CurrentUserInfoTypes.LOAD_CURRENTUSERINFO_SUCCESS:
			return {
				...state, loading: false, error: false, data: aciton.payload.data
			};
		case CurrentUserInfoTypes.LOAD_CURRENTUSERINFO_FAILURE:
			return {
				...state, loading: false, error: true
			};
		default:
			return state;
	}
};

export default {
	reducer,
	currentUserReducer
};
