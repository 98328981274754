import React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import "./UserAgreement.scss";

interface State {
	key: any;
}
interface mineProps {}
interface DispatchProps {}
type Props = DispatchProps & RouteComponentProps & mineProps;

class UserAgreement extends React.PureComponent<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = {
			key: "",
		};
	}

	componentDidMount() {
		this.updateDocumentTitle();
		this.setState({
			key: Date.now(),
		});
	}

	componentDidUpdate() {
		this.updateDocumentTitle();
	}

	updateDocumentTitle() {
		document.title = "用户协议";
	}
	render() {
		return (
			<div className="userAgreement">
				<div className="content">
					一起脱单啊（以下简称“平台”）在此特别提醒您（⽤户）在使⽤本平台服务之前，请认真阅读本《一起脱单啊平台⽤户协议》（以下简称“协议”），确保您充分理解本协议中各条款。请您审慎阅读并选择接受或不接受本协议。除⾮您接受本协议所有条款，否则您⽆权注册、登录或使⽤本协议所涉服务。您的注册、登录、使⽤等⾏为将视为对本协议的接受，并同意接受本协议各项条款的约束。但⽆论您事实上是否在确认（签订）本协议前认真阅读了本协议内容，只要您确认（签订）本协议，则本协议即对您产⽣约束，届时您不应以未阅读本协议的内容或者未获得平台对您问询的解答等理由，主张本协议未成⽴、⽆效，或要求撤销本协议。
				</div>
				<div className="content">
					本协议约定平台与⽤户之间关于平台微信公众号服务、⼩程序、平台H5服务（以下简称“服务”）的权利义务。“⽤户”是指注册、登录、使⽤本服务的个⼈。平台有权根据业务发展需要不时地制订、修改本协议及/或各类单⾏的规则，并以微信公众号推⽂、⼩程序、H5通知的⽅式进⾏公告，不再单独通知您。变更后的协议和规则⼀经在平台公布后，⽴即⾃动⽣效（除⾮该规则单独明确了⽣效⽇期）。如果您不接受修改后的条款，请⽴即停⽌使⽤平台提供的服务，您继续使⽤平台提供的服务将被视为接受修改后的协议。
				</div>

				<div className="title">一、相关定义</div>
				<div>
					1.一起脱单啊平台：指微信公众号一起脱单啊、服务号一起脱单啊服务号、⼩程序一起脱单啊、H5一起脱单啊。
				</div>
				<div>2.嘉宾：指在平台发布交友贴的⽤户。</div>
				<div>3.发帖：指嘉宾通过平台发布征友信息的⾏为。</div>
				<div>4.联系：指⽤户希望通过平台嘉宾取得联系的⾏为。</div>
				<div>5.活动：指⽤户参与平台组织线下活动的⾏为。</div>

				<div className="title">⼆、注册及使⽤资格</div>
				<div>1.申请注册成为本平台⽤户应同时满⾜下列全部条件：</div>
				<div className="child">
					a.在注册之时必须年满18周岁且具有完全⺠事⾏为能⼒；
				</div>
				<div className="child">
					b.在注册之⽇以及此后使⽤平台交友服务期间必须是单身状态，包括未婚、离异或是丧偶；
				</div>
				<div>2.为更好的享有平台提供的服务，您应该：</div>
				<div className="child">
					a.根据平台要求向平台提供本⼈真实、正确、最新及完整的资料；
				</div>
				<div className="child">
					b.随时更新登记资料，保持其真实性及有效性；
				</div>
				<div className="child">
					c.征友过程中，务必保持征友帐号的唯⼀性。
				</div>
				<div>3.身份认证</div>
				<div className="child">
					a.为保证发帖嘉宾的学历真实性，在必要时需要⽤户提供学⽣证或毕业证等进⾏学历认证的资料以供平台审核。
				</div>
				<div className="child">
					b.若⽤户提供任何错误、不实或不完整的资料，或平台有理由怀疑资料为错误、不实或不完整及违反⽤户注册条款的，或有辱骂、诽谤其它⽤户的，平台有权修改⽤户的注册昵称、签名、头像、个⼈详细资料⻚等，或暂停或终⽌该⽤户的帐号，或暂停或终⽌提供平台提供的全部或部分服务。
				</div>

				<div className="title">三、隐私权</div>
				<div>本规则所涉及的“隐私”是指：</div>
				<div className="child">
					a.在⽤户注册平台⽹络帐户时，使⽤其它平台产品或服务，访问平台⻚⾯,或参加任何形式的⽤户活动时，平台收集的⽤户的个⼈身份识别资料，包括但不限于⽤户的姓名、昵称、⼿机号、微信号、出⽣⽇期、性别、职业、所在⾏业、个⼈收⼊、兴趣爱好、个⼈介绍、家庭情况、情感经历、择偶标准经历等。
				</div>
				<div className="child">
					b.⽹站⾃动接收并记录⽤户的浏览器和服务器⽇志上的信息，包括但不限于⽤户的IP地址、在线、⽆线信息、信件等资料。
				</div>
				<div className="child">
					c.平台收集上述信息有可能⽤于：优化服务、改进⽹⻚内容，满⾜⽤户对某种产品、活动的需求、通知⽤户最新产品、活动信息、进⾏脱敏数据研究、或根据法律法规要求的⽤途。
				</div>
				<div className="child">
					d.为了确保⽤户的隐私和信息安全。在某些情况下，平台使⽤通⾏标准的保密系统，确保资料传送的安全性。
				</div>
				<div className="child">
					e.⽤户选择不进⾏公开的个⼈信息不会被出租、出售或以任何⽅式透露给任何第三⽅，但以下情况除外：
				</div>
				<div className="child">
					<div>①用户依照平台应征规则获得嘉宾联系方式的；</div>
					<div>②用户明确同意让第三方共享资料； </div>
					<div>③平台与第三方合作的脱敏信息用于大数据研究分析；</div>
					<div>
						④透露用户的个人资料是提供用户所要求的产品和服务的必要条件； 
					</div>
					<div>
						⑤为保护平台、用户及社会公众的权利、财产或人身安全； 
					</div>
					<div>
						⑥根据法律法规的规定，向司法机关提供；受到黑客攻击导致用户信息泄漏的；
					</div>
				</div>

				<div className="title">四、账户管理</div>
				<div>
					1.平台H5帐号的所有权归平台所有，⽤户完成申请注册⼿续后，获得H5帐号的使⽤权，该使⽤权仅属于初始申请注册⼈，禁⽌赠与、借⽤、租⽤、转让或售卖。
				</div>
				<div>2.⽤户可以通过</div>
				<div className="child">a.查看与编辑个人资料页，</div>
				<div className="child">
					b.“我的”⻚⾯⾥的“编辑资料”⻚⾯来查询、更改、删除帐户上的个⼈资料、注册信息及传送内容等，但需注意，删除有关信息的同时也会删除⽤户储存在系统中的⽂字和图⽚。⽤户需承担该⻛险。
				</div>
				<div>
					3.⽤户有责任妥善保管注册帐号信息安全，因⽤户保管不善可能导致遭受盗号，责任由⽤户⾃⾏承担。⽤户需要对注册帐号以的⾏为承担法律责任。⽤户同意在任何情况下不使⽤其他⽤户的帐号。在⽤户怀疑他⼈使⽤其帐号时，⽤户同意⽴即通知平台。
				</div>
				<div>
					4.⽤户应遵守本协议的各项条款，正确、适当地使⽤本服务，如因⽤户违反本协议中的任何条款，平台在通知⽤户后有权依据协议中断或终⽌对违约⽤户H5帐号提供服务。同时，平台保留在任何时候收回H5帐号、⽤户名的权利。
				</div>

				<div className="title">五、数据储存</div>
				<div>
					1.平台不对用户在本服务中相关数据的删除或储存失败负责。
				</div>
				<div>
					2.平台可以根据实际情况自行决定用户在本服务中数据的最长储存期限，并在服务器上为其分配数据最大存储空间等。用户可根据自己的需要自行备份本服务中的相关数据。
				</div>
				<div>
					3.如用户停止使用本服务或本服务终止，平台可以从服务器上永久地删除用户的数据。本服务停止、终止后，平台没有义务向用户返还任何数据。
				</div>

				<div className="title">六、法律责任</div>
				<div>
					1.如果平台发现或收到他⼈举报或投诉⽤户违反本协议约定的，平台有权不经通知随时对相关内容，包括但不限于⽤户资料、应征记录进⾏审查、删除，并视情节轻重对违规帐号处以包括但不限于警告、帐号封禁、功能封禁的处罚，且通知⽤户处理结果。
				</div>
				<div>
					2.被封禁⽤户可向平台提交申诉，平台将对申诉进⾏审查，并⾃⾏合理判断决定是否变更处罚措施。
				</div>
				<div>
					3.⽤户理解并同意，平台有权依合理判断对违反有关法律法规或本协议规定的⾏为进⾏处罚，对违法违规的任何⽤户采取适当的法律⾏动，并依据法律法规保存有关信息向有关部⻔报告等，⽤户应承担由此⽽产⽣的⼀切法律责任。
				</div>
				<div>
					4.⽤户理解并同意，因⽤户违反法律法规或本协议约定，导致或产⽣的任何第三⽅主张的任何索赔、要求或损失，包括但不限于合理的律师费，⽤户应当赔偿平台与合作公司、关联公司，并使之免受损害。
				</div>

				<div className="title">七、免责声明</div>
				<div>
					1.平台不保证所提供的服务将最终帮助您找到您的伴侣,亦不保证嘉宾将会通过您的好友申
					请，⽆论您是否向平台⽀付了任何费⽤。
					、功能封禁的处罚，且通知⽤户处理结果。
				</div>
				<div>
					2.平台并不保证所提供的各类服务的稳定性、及时性，对于由此给您造成的任何不便或损
					害，您于同意本使⽤规则时已表示理解并同意免除平台与此相应的法律责任。如在本平台运营
					过程中出现我们误将您的帐户终⽌，或变更您的有关信息，或部分停⽌您的各类权限，则我们
					将在您提出书⾯通知或⾃⾏发现上述错误后合理期间内处理该等事宜，若因此给您带来不便甚
					⾄造成损失，平台不承担任何法律责任。
				</div>
				<div>
					3.您承认对于您利⽤本平台提供的服务或信息⽽从事的各类⾏为，包括在本平台发布各类信
					息，利⽤本平台提供之信息联系其他⽤户等，均为您个⼈⾏为且您个⼈对该类⾏为承担完全责
					任。
				</div>
				<div>
					4.您清楚认知到⾃⼰同意公开发布在平台上的信息可能会被任何第三⽅知晓、转载或被扩
					散，若因此给您带来不便甚⾄造成损失，平台不承担任何法律责任。
				</div>
				<div>
					5.您清楚认知到在嘉宾与⽤户沟通交流的环节中，双⽅都可以获知对⽅进⼀步的个⼈信息，
					平台并⽆义务也⽆能⼒监管任何⼀⽅的个⼈⾏为，也不对其可能造成的任何不利后果或损害承
					担任何法律责任。
				</div>
				<div>
					6.对于⽤户发布的照⽚、资料、证件等，我们已采⽤相关措施并已尽合理努⼒进⾏审核，但
					不保证其内容的正确性、合法性或可靠性，相关责任由上传上述内容的⽤户负责。对于上传的
					照⽚，其它⽤户应以⾃⼰的独⽴判断来确定照⽚或⽂字信息的真实性、准确性，并独⽴承担可
					能产⽣的不利后果和责任，平台不承担任何法律责任。
				</div>
				<div>
					7.平台对所有⽤户⾃发组织的活动、⾃发成⽴的组织不负责任。
				</div>
				<div>
					8.对于本平台策划、发起、组织或是承办的任何⽤户活动（包括但不限于收取费⽤以及完全
					公益的活动），平台不对上述活动的效果向⽤户作出任何保证或承诺，也不担保活动期间⽤户
					⾃身⾏为的合法性、合理性。由此产⽣的任何对于⽤户个⼈或者他⼈的⼈身或者是名誉以及其
					他损害，本平台不承担任何责任。
				</div>
				<div>
					9.您同意并认可，对于因超出平台合理控制范围的任何事件或情况，包括但不限于战争、⼊
					侵、恐怖袭击、⾃然灾害或其他不可抗⼒，以及⿊客袭击、电脑病毒、⽹络故障等情况所给您
					造成的任何损失或损害，平台⽆需承担任何赔偿责任，并不被视为违反本使⽤规则。尽管有前
					述约定，平台将采取合理⾏动积极促使服务恢复正常
				</div>

				<div className="title">八、服务的变更、中断、终止</div>
				<div>
					1.鉴于⽹络服务的特殊性，⽤户同意平台有权随时变更、中断或终⽌部分或全部的服务（包
					括收费服务）。平台变更、中断或终⽌的服务，平台应当在变更、中断或终⽌之前通知⽤户，
					并应向受影响的⽤户提供等值的替代性的服务；如⽤户不愿意接受替代性的服务，如果该⽤户
					已经向平台⽀付的价款，平台应当按照该⽤户实际使⽤服务的情况扣除相应价款之后将剩余的
					价款退还⽤户。
				</div>
				<div>
					2.如发⽣下列任何⼀种情形，平台有权变更、中断或终⽌向⽤户提供的免费服务或收费服
					务，⽽⽆需对⽤户或任何第三⽅承担任何责任：
				</div>
				<div className="child">
					a.根据法律规定或本协议要求⽤户应提交真实信息，⽽⽤户提供的个⼈资料不真实、或与注
					册时信息不⼀致⼜未能提供合理证明；
				</div>
				<div className="child">
					b.⽤户违反相关法律法规或本协议的约定；
				</div>
				<div className="child">c.按照法律规定或有权机关的要求；</div>
				<div className="child">d.出于安全的原因或其他必要的情形。</div>

				<div className="title">九、其他</div>
				<div>
					1.平台郑重提醒⽤户注意本协议中免除平台责任和限制⽤户权利的条款，请⽤户仔细阅读，自主考虑风险。
				</div>
				<div>
					2.本协议的效⼒、解释及纠纷的解决，适⽤于中华⼈⺠共和国法律。若⽤户和平台之间发⽣
					任何纠纷或争议，⾸先应友好协商解决，协商不成的，⽤户同意将纠纷或争议提交湖南省长沙市长沙县人民法院管辖。
				</div>
				<div>
					3.本协议的任何条款⽆论因何种原因⽆效或不具可执⾏性，其余条款仍有效，对双⽅具有约
					束⼒。
				</div>
				<div>
					4.由于互联⽹⾼速发展，您与平台签署的本协议列明的条款可能并不能完整罗列并覆盖您与
					平台所有权利与义务，现有的约定也不能保证完全符合未来发展的需求。因此，平台隐私权政
					策、平台使⽤规则等不时发布的单项政策、规则均为本协议的补充协议，与本协议不可分割且
					具有同等法律效⼒。如您使⽤本平台服务，视为您同意上述补充协议。
				</div>
			</div>
		);
	}
}

export default withRouter(UserAgreement);
