// @ts-nocheck
import { call, put } from 'redux-saga/effects';
import {
	getCustomerByID, getFeeType, getRedFeeType, getRedFreeConfig
} from '@service/api';
import {
	loadUserDetailSuccess,
	loadUserDetailFailure,
	loadGetFeeTypeSuccess,
	loadGetFeeTypeFailure,
	loadGetRedFeeTypeSuccess,
	loadGetRedFeeTypeFailure,
	loadGetRedFeeConfigSuccess,
	loadGetRedFeeConfigFailure
} from './actions';

/**
 * 用户详情
 * @param action
 */
export function* loadUserDetailData(action:any) {
	try {
		const response = yield call(getCustomerByID, action.payload);
		yield put(loadUserDetailSuccess(response));
	} catch (e) {
		yield put(loadUserDetailFailure());
	}
}
/**
 * 个人信息支付列表
 * @param action
 */
export function* loadGetFeeType() {
	try {
		const response = yield call(getFeeType);
		yield put(loadGetFeeTypeSuccess(response));
	} catch (e) {
		yield put(loadGetFeeTypeFailure());
	}
}

/**
 * 红娘支付列表
 * @param action
 */
export function* loadGetRedFeeType() {
	try {
		const response = yield call(getRedFeeType);
		yield put(loadGetRedFeeTypeSuccess(response));
	} catch (e) {
		yield put(loadGetRedFeeTypeFailure());
	}
}

/**
 * 红娘详情页
 * @param action
 */
export function* loadGetRedFreeConfig() {
	try {
		const response = yield call(getRedFreeConfig);
		yield put(loadGetRedFeeConfigSuccess(response));
	} catch (e) {
		yield put(loadGetRedFeeConfigFailure());
	}
}
