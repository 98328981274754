import React, { Component } from 'react';
import {
	AppBar, Toolbar, Typography, Button
} from '@material-ui/core';
import { ArrowBackIos } from '@material-ui/icons';
import {
	getCustomerInfo, getQuestions, getUserInfoByCode, getCurrentUserInfo, updateCustomerInfo
} from '@service/api';
import * as querystring from 'querystring';
import {
	List,
	InputItem,
	Switch,
	DatePicker,
	Picker,
	TextareaItem,
	Toast
} from 'antd-mobile';
import './_coreInfo.scss';
import { RouteComponentProps, withRouter } from 'react-router';
import cityData from '@libs/cityFormat';

interface PrivacyInformation {
	微信号: string;
	手机号: number | string;
    QQ号: number | string,
    年收入: number | string,
    是否购房: string,
    其他经济条件: string,
    恋爱经历: string,
    家庭背景: string
}

interface IdealObject {
	年龄范围: any[];
	坐标: string;
	外貌: string;
	学历: string;
	性格: string;
	是否有房: string;
	年收入: string;
	补充: string;
}

interface Personal {
	name: string;
	gender: string;
	height: number | string;
	birthday: any;
	education: string;
	job: string;
	living_place: string[];
	hometown: string[];
	is_only: string[];
	only_content: string;
	cover_img: string;
	img_list: string[];
}

interface StringArr {
	label: string;
	value: string;
}
interface State {
	showMatchmaker: boolean;
	qrcode_image: string;
	qrcode_area: string;
	temp: any;
	step: number;
	checked: boolean;
	info: Personal;
	educationList: StringArr[];
	privacy_information: PrivacyInformation;
	introduce: string[];
	ideal_object: IdealObject;
	ageList: any[];
	titleList: string[];
	cityData: any[];
	questionModal: boolean;
	questions_answers: any[];
	qaIndex: number;
	questionChecked: number;
	questionList: any[];
	is_on_wall: boolean;
	on_wall: string;
}

type Props = RouteComponentProps;
class WriteInformation extends Component<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = {
			is_on_wall: true,
			on_wall: "是",
			qrcode_image: "",
			qrcode_area: "",
			showMatchmaker: false,
			temp: {},
			step: 0,
			checked: true,
			questionChecked: -1,
			questionModal: false,
			educationList: [
				{
					label: '专科',
					value: '专科'
				},
				{
					label: '本科',
					value: '本科'
				},
				{
					label: '硕士',
					value: '硕士'
				},
				{
					label: '博士',
					value: '博士'
				}
			],
			info: {
				name: '',
				gender: '男',
				height: '',
				birthday: new Date(1990, 0, 1, 0, 0, 0),
				education: '',
				job: '',
				living_place: [],
				hometown: [],
				is_only: ['是'],
				only_content: '',
				cover_img: '',
				img_list: []
			},
			privacy_information: {
				微信号: '',
				手机号: '',
				QQ号: '',
				年收入: '',
				家庭背景: '',
				是否购房: '',
				其他经济条件: '',
				恋爱经历: ''
			},
			qaIndex: 0,
			introduce: ['', '', ''],
			ideal_object: {
				坐标: '',
				外貌: '',
				学历: '',
				年龄范围: [0, 99],
				性格: '',
				是否有房: '',
				年收入: '',
				补充: ''
			},
			ageList: [],
			titleList: ['核心资料', '自我介绍', '理想对象', '隐私信息', '问答环节'],
			cityData,
			questionList: [],
			questions_answers: [{ A: '', Q: '' }, { A: '', Q: '' }, { A: '', Q: '' }]
		};
	}

	async componentDidMount() {
		window.scrollTo(0, 0);
		const { location: { search } } = this.props;
		const queryArr:string[] = search.split('?');
		if (queryArr.length === 2) {
			const obj:any = querystring.parse(queryArr[1]);
			const { code } = obj;
			if(code) {
				await getUserInfoByCode(code);
			}
		}
		await getCurrentUserInfo();
		const { match: { params } } :any = this.props;

		if (params && params.step) {
			this.setState({
				step: parseInt(params.step)
			});
		}
		document.title = '一起脱单啊';
		let ageList: any[] = [];

		ageList = this.loopAge(ageList);

		ageList = ageList.map((age:any) => {
			age.children = [];
			age.chilren = this.loopAge(age.children, age.value + 1, 46);
			return age;
		});

		this.setState({
			ageList
		});

		getCustomerInfo().then((res:any) => {
			this.updateRes(res);
		});

		getQuestions().then((res) => {
			const questionList: { content: any; }[] = [];
			Object.values(res).forEach((item:any) => {
				questionList.push({
					content: item
				});
			});
			this.setState({
				questionList
			});
		});
	}

	loopAge =(agetList: any[], ageDefault = 18, max = 45) => {
		for (let age = ageDefault; age < max; age++) {
			agetList.push({
				label: `${age}`,
				value: age
			});
		}
		return agetList;
	}

	async preClick() {
		window.scrollTo(0, 0);
		const { match, history } = this.props;
		const { step } = this.state;
		let { url } = match;
		url = url.slice(0, url.length - 1);
		url += step - 1;
		this.setState({
			step: step - 1
		});
		this.updateCustomer();
		history.push(url);
	}

	updateRes(res:any) {
		res.birthday = new Date(res.birthday);
		this.setState({
			qrcode_area: res.qrcode_area,
			qrcode_image: res.qrcode_image,
			is_on_wall: !!res.is_on_wall,
			on_wall: !!res.is_on_wall ? "是" : "否",
			temp: res,
			info: Object.assign(this.state.info, res),
			introduce: Object.assign(this.state.introduce, res.introduce),
			questions_answers: Object.assign(this.state.questions_answers, res.questions_answers),
			ideal_object: Object.assign(this.state.ideal_object, res.ideal_object),
			privacy_information: Object.assign(this.state.privacy_information, res.privacy_information)
		});
	}

	async updateCustomer() {
		const { match } = this.props;
		const paramer: any = match.params;
		const {
			introduce, questions_answers, ideal_object, privacy_information, info,
			is_on_wall
		} = this.state;
		const res = await updateCustomerInfo(Object.assign({},this.state.temp, info, {
			is_on_wall,
			introduce,
			questions_answers,
			ideal_object,
			privacy_information,
			birthday: new Date(info.birthday).getTime(),
			operate_id: this.state.temp.operate_id  || paramer.id,
			"operate_type": this.state.temp.operate_type || "买微信"
		}));
		this.updateRes(res);
	}

	verifCoreData() {
		let { info : { name, height, birthday , living_place, hometown, education,  job, }, privacy_information: { 微信号 , 年收入, 手机号 } } = this.state;
		if(!name) {
			Toast.info("请输入姓名",3);
			return false;
		}
		if(!height) {
			Toast.info("请输入身高",3);
			return false;
		}
		if(new Date(birthday).getTime() == 0) {
			Toast.info("请输入出生年月",3);
			return false;
		}
		living_place = living_place.filter(item=> item);
		hometown = hometown.filter(item=> item);
		if(living_place.length == 0) {
			Toast.info("请输入现居地",3);
			return false;
		}
		if(hometown.length == 0) {
			Toast.info("请输入家乡",3);
			return false;
		}
		if(!education) {
			Toast.info("请填写学历",3);
			return false;
		}
		if(!job) {
			Toast.info("请填写职业",3);
			return false;
		}
		if(!微信号) {
			Toast.info("请填写您的微信号",3);
			return false;
		}
		if(!手机号) {
			Toast.info("请填写您的手机号",3);
			return false;
		}
		let reg_tel = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
		if(!reg_tel.test(`${手机号}`)) {
			Toast.info("请填写正确的手机号",3);
			return false;
		}
		if(!年收入) {
			Toast.info("请填写您的年收入",3);
			return false;
		}
		return true;
	}

	async nextClick() {
        window.scrollTo(0, 0);
        const { location: { search } } = this.props;
		const { step } = this.state;
		if(step == 0) {
			let flag = this.verifCoreData();
			if(!flag) {
				return;
			}
		}
		await this.updateCustomer();
		let searchTemp: any = querystring.parse(search.split("?")[1]);
		let domain = "";
		if(searchTemp.third) {
			domain = `/detail/${searchTemp.id}/${encodeURIComponent(searchTemp.name)}/${searchTemp.third}?show=1`;
		} else {
			domain = `/detail/${searchTemp.id}/${encodeURIComponent(searchTemp.name)}?show=1`;
		}
        window.location.href = domain;
		return;
	}

	renderCoreData = () => {
		const {
			info,
			info: {
				name, gender, birthday, education, job, height, living_place, hometown, is_only, only_content
			},
			educationList,
			cityData,
			privacy_information,
			privacy_information: { 微信号, 年收入, 手机号 },
			is_on_wall,
			on_wall
		} = this.state;
		return (
			<div className="core__data">
				<List>
					<InputItem
						placeholder="请填写您的姓名"
						value={name}
						onChange={(val) => {
							this.setState({
								info: { ...info, name: val }
							});
						}}
					>
						姓名
					</InputItem>
					<List.Item
						extra={(
							<Switch
								checked={gender == '男'}
								color="#EF5050"
								name={gender}
								onChange={(checked) => {
									console.log(checked);
									this.setState({
										checked,
										info: {
											...info,
											gender: checked ? '男' : '女'
										}
									});
								}}
							/>
						)}
					>
						性别
					</List.Item>
					<InputItem
						placeholder=""
						extra="cm"
						type="number"
						value={`${height == 0 ? '' : height}`}
						onChange={(val) => {
							this.setState({
								info: { ...info, height: Number(val) }
							});
						}}
					>
						身高
					</InputItem>
					<DatePicker
						mode="date"
						title="出生年月"
						extra="请选择出生年月"
						minDate={new Date(1970, 0, 1, 0, 0, 0)}
						value={birthday}
						onChange={(birthday: Date) => {
							this.setState({
								info: { ...info, birthday }
							});
						}}
					>
						<List.Item>出生年月</List.Item>
					</DatePicker>
					<Picker
						extra="请选择现居地"
						data={cityData}
						title="区域"
						value={living_place}
						onOk={(living_place: string[]) => {
							this.setState({
								info: { ...info, living_place }
							});
						}}
					>
						<List.Item arrow="down">现居地</List.Item>
					</Picker>
					<Picker
						extra="请选择家乡"
						data={cityData}
						title="区域"
						value={hometown}
						onOk={(hometown: string[]) => {
							this.setState({
								info: { ...info, hometown }
							});
						}}
					>
						<List.Item arrow="down">家乡</List.Item>
					</Picker>
					<Picker
						data={educationList}
						cols={1}
						extra="请选择学历"
						value={[education]}
						onOk={(val: any) => {
							this.setState({
								info: { ...info, education: val[0] }
							});
						}}
					>
						<List.Item arrow="down">学历</List.Item>
					</Picker>
					<InputItem
						placeholder="请填写您的职业"
						value={job}
						onChange={(val: any) => {
							this.setState({
								info: { ...info, job: val }
							});
						}}
					>
						职业
					</InputItem>
					<InputItem
						placeholder=""
						type="number"
						value={`${手机号}`}
						onChange={(val) => {
							this.setState({
								privacy_information: { ...privacy_information, 手机号: Number(val) }
							});
						}}
					>
						手机号
					</InputItem>
					<InputItem
						placeholder="请填写您的微信号"
						value={微信号}
						onChange={(val) => {
							this.setState({
								privacy_information: { ...privacy_information, 微信号: val }
							});
						}}
					>
						微信号
					</InputItem>
					<InputItem
						placeholder=""
						extra="万"
						type="number"
						value={`${年收入 == 0 ? '' : 年收入}`}
						onChange={(val) => {
							this.setState({
								privacy_information: { ...privacy_information, 年收入: Number(val) }
							});
						}}
					>
						年收入
					</InputItem>
				</List>
			</div>
		);
	};

	onWrapTouchStart = (e: any) => {
		// fix touch to scroll background page on iOS
		if (!/iPhone|iPod|iPad/i.test(navigator.userAgent)) {
			return;
		}
		let pNode = null;
		const matchesSelector = e.target.matches || e.target.webkitMatchesSelector || e.target.mozMatchesSelector || e.target.msMatchesSelector;
		while (e.target) {
			if (matchesSelector.call(e.target, '.am-modal-content')) {
				pNode = e.target;
			}
			e.target = e.target.parentElement;
		}

		if (!pNode) {
			e.preventDefault();
		}
	}

	render() {
		const { step } = this.state;

		return (
			<div className="write" key={step}>
				<AppBar position="fixed">
					<Toolbar className="app__header">
                        <ArrowBackIos onClick={() => {
								this.props.history.goBack()
							}}
						/>
						<Typography className="title">核心资料</Typography>
						<Button
							onClick={() => this.nextClick()}
							className="next__btn"
						>
							获取微信
						</Button>
					</Toolbar>
				</AppBar>
				<div className="tips">
					请务必填写自己的真实信息，信息绝对不会公开！填写完成后点击提交即可～
				</div>
				{ this.renderCoreData() }
			</div>
		);
	}
}

export default withRouter(WriteInformation);
